ol.officeGrops {
  display: flex;
  width: 100%;
  padding: 0;
  background-color: #f8f8f8d4;
  border-radius: 8px;
  margin-top: 35px;
  /*box-shadow: 0 0 74px 0 #dedede;*/
}

ol.officeGrops > li {
  margin: 3px 25px;
  width: 20%;
  display: inline-block;
  text-align: center;
  vertical-align: text-top;
}

ol.officeGrops li > ul {
  padding: 0;
}

ol.officeGrops ul > li {
  width: 100%;
  list-style: none;
  color: #000;
  padding: 15px 3px;
  border-radius: 6px;
  width: 80%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s;
  margin: 10px auto;
}

ol.officeGrops ul > li > div {
  background-color: #0000000a;
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 5px;
}

ol.officeGrops ul > li.green {
  background-color: #59a31421;
}
ol.officeGrops ul > li.cand_dark_yellow {
  background-color: #b19e179c;
}
ol.officeGrops ul > li.act_dark_green {
  background-color: #85bb65;
}
ol.officeGrops ul > li.yellow {
  background-color: #ffff0038;
}
ol.officeGrops ul > li.light-gray {
  background-color: #00000026;
}

ol.officeGrops ul > li.act_dark_blue {
  background-color: #55c0ff;
}

ol.officeGrops ul > li.act_dark_blue_second {
  background-color: #55c0ff;
  opacity: 0.5;
}

ol.officeGrops ul > li.cube-inactive {
  background-color: #55c0ff;
  opacity: 0.5;
}

ol.officeGrops ul > li.cube {
  background-color: #c5e9ff;
}

ol.officeGrops ul > li {
  box-shadow: 0 2px 4px 1px #0000001f;
}

ol.officeGrops ul > li.yellow > div {
  background-color: #00000014;
}

@media screen and (max-width: 1400px) {
  ol.officeGrops {
    display: inherit;
    width: 100%;
    overflow: unset;
  }
  ol.officeGrops li > ul {
    display: flex;
    width: 92%;
    overflow: auto;
  }

  ol.officeGrops ul > li {
    margin: 0;
    width: unset;
    margin-right: 20px;
  }

  ol.officeGrops > li {
    width: 100%;
    list-style: none;
  }

  ol.officeGrops > li h3 {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
}
