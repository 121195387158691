.MuiAutocomplete-root.statReport.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 250px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}

button#downloadAll {
  display: none;
}

button#downloadAllReport {
  display: none;
}

.header_icon_title span {
  margin-right: 10px;
}

.header_icon_title svg {
  vertical-align: text-bottom;
}
.select_student {
  display: flex;
  justify-content: flex-end;
  align-content: space-between;
  flex-direction: inherit;
}

.student_issue .MuiFormControl-root {
  width: 100%;
}

.student_issue button {
  color: #fff;
}

.clearStudents {
  color: #000 !important;
}

.select_student button {
  padding: 0;
}

