/* p.MuiTypography-root.MuiTypography-body1 {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.MuiPaper-root.MuiAccordion-root.arcards.no_data.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    opacity: 0.5;
    pointer-events: none;
}

.ar_count_text > span {
    font-size: 15px;
}

.ar_card-container {
    width: 17%;
    height: 330px;
    background-position: center;
    background-size: contain;
    display: inline-block;
    border: 10px solid #59a315;
    border-radius: 20px;
    position: relative;
    margin: 10px;
    background-repeat: no-repeat;
}

.MuiTypography-root.ar_cards.MuiTypography-body1 {
    width: 100%;
}

.ar_card-title {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    background-color: #59a315;
    color: #fff;
    padding-top: 10px;
    font-size: 20px;
    text-transform: capitalize;
}

.dash-main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.no_left_margin.MuiButton-containedPrimary {
    margin-left: 0 !important;
}

.birth_date {
    padding: 20px;
}

.birth_date_item {
    padding: 0 20px;
    margin-left: 15px;
    margin-bottom: 20px;
}

.discounts_accordion.no_data {
    opacity: 0.7;
    pointer-events: none;
}

.cards:hover {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 8%), 0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 3px 1px rgba(0, 0, 0, 0.2);
}

.assign_book.homework button {
    width: 100%;
    margin: 0 !important;
    background-color: #ba27c3 !important;
}

.panel-back {
    width: 100%;
    height: 100vh;
    background: #e5e5e5;
    overflow: hidden;
    z-index: 0;
}

.space {
    margin-left: 50px;
}

.subtitle {
    padding: 15px 20px;
    text-align: left;
}

.base-container {
    scroll-behavior: smooth;
    flex: auto;
    padding: 25px 30px 100px 15px;
    /* height: calc(100vh - 16%); */
    overflow-y: auto;
}

.container-header {
    font-size: 1.1em;
    margin: 0 0 1%;
    font-weight: 100;
}

/*admin panel view cards */

.container-cards {
    display: flex;
    flex-flow: row;
    align-items: center;
    /*justify-content: space-between;*/
}

.cards {
    width: 250px;
    height: auto;
    transition: all 0.1s;
    border: 2px solid white;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 2%), 0px 2px 2px rgb(0 0 0 / 2%),
        0px 3px 1px rgb(0 0 0 / 2%);
    border-radius: 4px;
}

.cards h3 {
    font-size: 1.8em;
    font-weight: bold;
    margin: 0;
}

.cards p {
    margin: 0;
    font-size: 0.7em;
}

.card-img {
    width: 25%;
    margin: 1% 2%;
}

.card-right {
    display: flex;
    flex-direction: column;
    margin: 1% 5%;
}

a {
    text-decoration: none;
    color: black;
}
a:active {
    color: #ff6600;
}

.card-wrapper {
    display: flex;
    flex-direction: row;
    padding: 35px 20px;
}

.add_btn:hover {
    color: #ff6600;
    cursor: pointer;
}

/*francise panel view cards */

.container-form {
    display: flex;
    flex-flow: column;
}

.link-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.add-to-cite-block {
    /* top: 2%;
    right: 2%; */
    width: 22%;
    font-size: 0.75em;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.add-to-cite-block p {
    padding-left: 1vh;
}

.add-to-cite-img {
    width: 20px;
    padding: 7px;
    border-radius: 50%;
    background: green;
}

.form-header {
    color: green;
    font-size: 0.8em;
    padding: 1% 0 2% 0;
    margin: 0;
}

.regular-text {
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.64);
    margin: 0;
    padding: 0;
}
.container-form a {
    text-decoration: underline;
}

.link_container {
    /* font-size: 0.75em; */
    /* color: rgba(0, 0, 0, 0.64); */
    /* margin: 0 1% 1% 0; */
    padding: 0;
    /* width: 80%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.link_container p {
    margin-left: 1%;
}

.link-icons {
    width: 16px;
    height: 16px;
    margin: 0 1%;
    box-shadow: none;
    padding: 10px;
    border-radius: 50%;
}
.link-icons:hover {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 3px 1px rgba(0, 0, 0, 0.08);
    transition: all 350ms;
}

.copy-icon {
    margin: 0 1%;
    box-shadow: none;
    padding: 10px;
}

.copy-icon:hover {
    color: #ff6600;
}

.successCopy {
    position: absolute;
    top: 15%;
    right: 25%;
    padding: 0 5%;
}

.mini-card {
    width: 30%;
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.75);
    padding: 1% 1%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.inside-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 98%;
    margin-bottom: 1%;
}

.inside-wrapper-2 {
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 1%;
}

.inner-text {
    margin: 0;
}

.input-arrow {
    width: 16px;
}

.card {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    padding: 0 5%;
    /* justify-content: space-between; */
    font-size: 0.75em;
}

.card-area {
    width: 30%;
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.75);
    padding: 1% 1%;
    display: flex;
    flex-direction: column;
}

.card-area-2 {
    width: 30%;
    padding: 1% 1%;
    display: flex;
    flex-direction: column;
}

.header-area {
    font-weight: bold;
    margin-bottom: 1%;
}

.dash-select {
    padding: 0.2em 0.5em;
    border: none;
    background-color: #ffffff;
    background-position: right;
    background-repeat: no-repeat;
    color: rgba(0, 0, 0, 0.75);
    /* font-size: 1em !important; */
    padding: 1% 1%;
    display: flex;
    flex-direction: row;
    align-content: center;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

.card-area select {
    font-size: 0.75em !important;
}

/* .MuiInputBase-root {
        font-size: 1em !important;
} */

/*student panel view cards */

.add-to-cite-img {
    width: 20px;
    padding: 7px;
    border-radius: 50%;
    background: green;
}

.form-header {
    color: green;
    font-size: 0.8em;
    padding: 1% 0 2% 0;
    margin: 0;
}

.regular-text {
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.64);
    margin: 0;
    padding: 0;
}
.container-form a {
    text-decoration: underline;
}

.dash-icons {
    width: 16px;
    padding: 0 2%;
}

.link-container {
    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: space-between;
    width: 38%;
    margin-bottom: 1%;
}

.mini-card {
    width: 30%;
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.75);
    padding: 1% 1%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.inside-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 98%;
    margin-bottom: 1%;
}

.inner-text {
    margin: 0;
}

.input-arrow {
    width: 16px;
}

.card-area {
    width: 30%;
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.75);
    padding: 1% 1%;

    display: flex;
    flex-direction: column;
}

.card-area-2 {
    width: 30%;
    padding: 1% 1%;

    display: flex;
    flex-direction: column;
}

.header-area {
    font-weight: bold;
    margin-bottom: 1%;
}

.rows {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    margin: 1%;
}

.mini-card select {
    display: none;
}

@media screen and (max-width: 768px) {
    .sidebar-container {
        height: 100%;
    }

    .base-container {
        height: calc(100vh - 10%);
        margin-left: 0%;
    }

    /* .dash-main-container {
    height: 0vh;
    overflow: unset !important;
  } */

    .container-cards {
        flex-direction: column;
    }

    .cards {
        width: 250px;
        margin-bottom: 5%;
    }

    .link-wrapper {
        flex-direction: row;
    }

    .add-to-cite-block {
        width: 30%;
        display: flex;
        align-items: center;
        align-content: center;
    }

    .add-to-cite-block p {
        font-size: 0.9rem;
    }

    .container-header {
        font-size: 1.1em;
        margin: 0 0 5%;
        text-align: left;
    }

    .card-area {
        width: 95%;
        float: right;
    }

    .inside-wrapper {
        display: flex;
        width: 95%;
        flex-direction: column;
        margin-bottom: 2%;
    }

    .inside-wrapper-2 {
        display: flex;
        width: 90%;
        margin-bottom: 2%;
    }

    .mini-card {
        width: 95%;
        float: right;
    }
}

/*filtration table rooles for mobile */

@media screen and (max-width: 768px) {
    .ar_card-container {
        width: 93%;
        margin: 0;
    }

    section.dashSection .MuiPaper-elevation1 {
        width: 100%;
    }
    .MuiExpansionPanelActions-root {
        display: block !important;
    }
    .MuiExpansionPanelActions-root * {
        margin: 2% 0;
    }
}
