.MuiPaper-root.MuiAccordion-root.customTempInfo.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    margin-bottom: 15px;
}

.customTemplatesBlock {
    margin: 10px -0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 3px 1px #0000003b;
}

.customTemplatesKeys {
    margin-left: 10px;
}

.panel-back.themeColorDARK p.MuiTypography-root.modal_text-item {
    color: #fff;
}

.customTemplatesKeys > div {
    margin: 3px 0;
}

p.MuiFormHelperText-root.custom-error-style {
    white-space: pre-line;
    color: #fff;
}

p.MuiFormHelperText-root.custom-error-style.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
    white-space: pre-line;
    color: #f44336;
}

.customTemplatesBlock .MuiTypography-root.MuiTypography-body1 {
    color: #000;
    font-weight: 600;
}

.MuiPaper-root.MuiTableContainer-root.tableSpeed.MuiPaper-elevation1.MuiPaper-rounded {
    height: 400px;
}

.flex_bouth_sides {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.seq.abacus {
    box-shadow: 0 3px 8px 0 #f60;
}
button.MuiButtonBase-root.MuiIconButton-root.decrease_btn svg {
    color: #ff4700;
}

button.MuiButtonBase-root.MuiIconButton-root.increase_btn svg {
    color: #59a314;
}
iframe.formIframe.add {
    margin-bottom: 15px;
}
iframe.formIframe {
    border: 0;
    box-shadow: 0 2px 5px 0 #00000052;
    border-radius: 5px;
}

.MuiFormControl-root.MuiTextField-root.create_city_title {
    text-transform: capitalize;
}
p.MuiTypography-root.modal_text-item.MuiTypography-body1 {
    text-align: left;
    width: 100%;
    color: #00000085;
    font-size: 16px;
}

.MuiDialog-container .MuiTypography-root.MuiTypography-body1 {
    display: block;
}

.row.single {
    width: 100%;
}

.MuiDialogTitle-root h2.MuiTypography-root.MuiTypography-h6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.g_arcard:hover {
    color: #ff6600;
}

.MuiChip-root.animated.fadeIn.hw_copy_chips {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.row.item.full.left {
    justify-content: end;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.greenButton.Mui-disabled.Mui-disabled
    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: #000;
}

.MuiPaper-root.MuiTableContainer-root.payments_history_table.MuiPaper-elevation1.MuiPaper-rounded {
    margin-bottom: 40px;
    box-shadow: 0 3px 5px 1px #0000004a;
}

.row_checkbox {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.row_checkbox a.link_agreement {
    color: #2da10d;
    text-decoration: revert;
}

.row.item.full
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.request-button.greenButton {
    width: 100%;
    transition: all 0.2s;
}

.row.item.full.button {
    width: 100%;
}

p.MuiTypography-root.payments_method-modal.MuiTypography-body1 {
    padding-bottom: 25px;
}

.row.select_full p.MuiTypography-root.MuiTypography-body1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.row.select_full p.MuiTypography-root.MuiTypography-body1 svg {
    margin-left: 20px;
}

.payments_error {
    cursor: pointer;
}

.back-black-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.56);
    display: flex;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    flex-direction: column;
}

.data_line {
    width: 800px;
}

.space_for_message {
    width: 700px !important;
}

.row.item.full.sales {
    display: block;
}

.sales_output {
    padding-bottom: 20px;
}

.sales_output
    .MuiButtonBase-root.MuiChip-root.MuiChip-colorPrimary.MuiChip-clickableColorPrimary.MuiChip-clickable {
    margin-right: 20px;
}

.MuiPaper-root.MuiAccordion-root.referer.discounts_accordion.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    background-color: #59a31452;
}

.MuiPaper-root.MuiAccordion-root.family.discounts_accordion.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    background-color: #0037ff30;
}

button.g_arcard {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #59a314;
    color: #59a314;
    font-weight: 500;
    text-transform: none;
    color: #fff;
    font-size: 19px;
    background: #59a314;
}

button.g_arcard:hover {
    border: 1px solid #ff6600;
}

.row.homework {
    display: block;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.greenButton {
    color: #fff;
    border-radius: 35px;
}

tr.MuiTableRow-root.error td {
    color: #fff;
}

.select_student.sales {
    justify-content: end;
}

.select_student.sales button {
    margin-left: 10px;
}

.row.select_full.expierd .discounts_accordion {
    background-color: #00000024;
    color: #00000091;
}

.text_template textarea {
    white-space: pre-line;
}

.MuiExpansionPanelDetails-root.sales_item {
    display: block;
    width: 91%;
    padding: 10px 20px;
}

.MuiExpansionPanelDetails-root.sales_item
    .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 10px 0;
}

td.MuiTableCell-root.MuiTableCell-body.edited {
    color: #000000;
    font-weight: 600;
}

.full_width {
    display: block;
}

.choosen_btn {
    background-color: #f36630 !important;
    color: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.stat_title_digit {
    width: auto;
    display: inline-block;
    margin-right: 20px;
}

button.MuiButtonBase-root.inactive {
    opacity: 0.5;
    pointer-events: none;
}

.maskedInput {
    width: 100%;
    font-size: 20px;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #00000045;
    outline-offset: 0;
    padding-bottom: 10px;
    margin: 17px 0;
}

.pay_btn {
    text-align: center;
    padding: 35px 0;
}

.pay_btn div:hover {
    background-color: #288a0c;
}

.pay_btn div {
    cursor: pointer;
    background-color: #2ca00a;
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0 #00000087;
    width: 70%;
    margin: 0 auto;
    font-size: 16px;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    flex-direction: inherit;
}

.row.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    flex-direction: column;
}

.row.no_data h6 {
    text-align: center;
    font-size: 0.9em;
}

.row.item.date_picker .MuiFormControl-root {
    width: 100%;
}

table.history-table.ar {
    text-align: left;
    width: 100%;
}

.row.item.full .MuiFormControl-root {
    width: 100%;
}

.sub-row-item.full .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

table.history-table.ar td {
    border-top: 1px solid #00000036;
    padding: 10px;
}

tr.history_table_header.ar th:last-child {
    text-align: right;
}

table.history-table.ar tbody > tr > td:last-child {
    text-align: right;
}

table.history-table.ar th {
    color: #00000082;
    font-weight: 100;
    font-size: 14px;
    padding: 10px;
}

tr.history_table_header.ar {
    border: 0;
}

table.history-table.ar tr {
    border: 1px solid #000;
    font-size: 0;
    /* padding: 0; */
}

table.history-table.ar tbody > tr:last-child td {
    border-bottom: 1px solid #00000036;
}

.item.css-bon {
    height: 10px;
}
.row.double .MuiTextField-root {
    width: 48.7%;
}
.row.double .MuiTextField-root:nth-child(1) {
    margin-right: 10px;
}

.row .icon {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
}
.row.select_full .MuiFormControl-root {
    width: 100%;
}
.row.select_full .MuiGrid-item {
    width: 100%;
}
.row.select_full .MuiPaper-root {
    width: 100%;
}
.items {
    width: 100%;
    height: 4px;
    margin: 10px 0;
}
.row .MuiInputBase-root {
    width: 100%;
}

.row .itemIcon {
    display: inline-block;
}

.css-bon {
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
        90deg,
        rgb(238, 238, 238),
        rgb(245, 245, 245),
        rgb(238, 238, 238)
    );
    background-size: 200px 100%;
    display: inline-block;
    line-height: 1;
    width: 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    animation: 1.2s ease-in-out 0s infinite normal none running
        animation-16jpnkj;
}
.cover {
    position: absolute;
}

.cover.close {
    display: none;
}

.row.full .MuiTextField-root,
.MuiNativeSelect-root {
    width: 100%;
}
.sub-row.sale {
    width: 100%;
    text-align: center;
    background-color: #4caf50;
    color: #fff;
    padding: 10px 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    cursor: pointer;
}
h3.sale_subtitle {
    text-align: left;
    /* background-color: #f2994b; */
    color: #f2994b;
    padding: 10px 0;
}

.sub-row-sale {
    margin: 10px 0;
    height: 0;
}

.sale_block.active .sub-row-sale {
    height: auto;
}

.sub-row-sale .MuiFormControl-root {
    margin-bottom: 15px;
    display: block;
    vertical-align: text-bottom;
}

.sub-row-sale .MuiFormControl-root:nth-child(4) {
    margin-left: 9px;
    margin-top: 5px;
}

.sub-row-sale:nth-child(1) .MuiInput-root {
    width: 100%;
    height: 100vh;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.78);
}

.sub-row-sale.block {
    background-color: #00000014;
    padding: 15px 15px;
    border-radius: 2px;
}
.sale_block {
    opacity: 0;
    height: 0;
    transition: all 0.2s;
}

.row-sale {
    margin-bottom: 20px;
}

.sale_block.active {
    opacity: 1;
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid #5baf517a;
    width: 100%;
}
.back-black-wrapper.no_black {
    background-color: unset;
}
.animated {
    animation-duration: 0.2s;
}

.stat_data.true {
    background-color: #0cd60c38;
}

.stat_data.false {
    background-color: #ff00002b;
}

.stat_data {
    white-space: break-spaces;
    border-radius: 6px;
    overflow: auto;
    width: 94%;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.seq {
    width: 100%;
    box-shadow: 0px 3px 8px 0px #00000061;
    padding: 10px;
    border-radius: 10px;
}

.seq_block {
    margin-bottom: 15px;
}

.MuiPaper-root.MuiAccordion-root {
    width: 100%;
}

/*.MuiTypography-root.MuiTypography-body1 {*/
/*  width: 95%;*/
/*  !* display: flex;*/
/*  align-items: center;*/
/*  justify-content: flex-start; *!*/
/*}*/

.payments_accordion_data
    .MuiTypography-root.header_icon_title.MuiTypography-body1 {
    /* width: 20%; */
}

.selectedDate .MuiBox-root {
    display: inline-flex;
}

.modalwindow {
    top: 7%;
    left: 30%;
    min-width: 20%;
    max-width: 50%;
    background-color: #fff;
    padding: 3% 4%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow-y: auto;
    font-size: 20px;
}

.modalwindow h3 {
    margin: 0;
    font-size: 0.9em;
}
.row.ios {
    margin-bottom: 20%;
}

.row {
    display: flex;
    align-content: center;
    -webkit-box-orient: horizontal;
    justify-content: space-between;
    margin-bottom: 4%;
    position: relative;
}

.sub-row {
    width: 100%;
}

.sub-row-item {
    width: 100%;
}

.close_btn {
    cursor: pointer;
}

.request-input {
    width: 100%;
}

.MuiSvgIcon-root {
    opacity: 0.65;
}

.modalwindow input[disabled] {
    opacity: 1;
}

.modalwindow select,
.modalwindow label {
    opacity: 0.8;
}

.request-button {
    transition: all 0.1s;
    cursor: pointer;
    width: 85%;
    color: #ffffff !important;
    margin: 10px !important;
    border: none;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0px #00000047;
    border-radius: 35px;
}

.request-button.greenButton:hover {
    background-color: #fff;
    color: #00a10c;
}

.request-button:hover {
    background-color: #fff;
    color: #f26630;
}

.orangeButton {
    background-color: #ff6600 !important;
    margin: 5% 0 0 15%;
    border-radius: 35px !important;
}

.greenButton {
    background-color: #00850a !important;
    margin: 5% 15% 0 0;
    border-radius: 32px !important;
}
.bigGreenButton {
    background-color: #00a10c;
    color: #ffffff;
    width: 100%;
    margin: 1% 0;
    padding: 2% 5%;

    font-size: 0.8em;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 3px 1px rgba(0, 0, 0, 0.2);
}

.inwork-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inwork-cell p {
    font-size: 0.8em;
}

.view-link {
    font-size: 0.7em;
    color: #00a10c;
    text-decoration: underline;
    flex: 1 auto;
    align-self: center;
    justify-content: center;
}

h4 {
    font-size: 0.8em;
}

.action_btn {
    width: 49%;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 3px 1px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 1.5vh;
    font-size: 0.7em;
}

.edit_btn {
    color: #2d9cdb;
}

.delete_btn {
    color: #eb5757;
}

.history-table {
    text-align: center;
    font-size: 1rem;
    top: 0;
    left: 0;
}

.history-table td {
    font-size: 0.8rem;
}
table.history-table tr {
    margin: 0;
    padding: 0;
    border-spacing: 0 !important;
}

tr.history_table_header td {
    color: #444444;
    font-size: 14px;
}

table.history-table td {
    padding: 11px;
}

@media screen and (max-width: 768px) {
    .stat_data {
        word-break: break-all;
    }
}

@media screen and (max-height: 600px) {
    .modalwindow {
        padding: 5% !important;
    }
}

@media screen and (max-width: 700px) {
    .row.double .MuiTextField-root {
        width: 100%;
        margin: 0;
    }

    .row.double .MuiTextField-root:nth-child(1) {
        margin: 0;
        margin-bottom: 13px;
    }
    .modalwindow {
        top: 2%;
        left: 5%;
        min-width: 92%;
        max-width: 80%;
        max-height: 100vh;
        background-color: #fff;
        padding: 3% 4%;
        display: flex;
        flex-direction: column;
        z-index: 999999999999;
        overflow-y: auto;
        padding-top: 15%;
        padding-bottom: 25%;
    }

    .modalwindow h3 {
        margin: 4% 0;
    }
    .greenButton {
        float: right;
        margin: 10%;
    }
    .orangeButton {
        float: left;
        margin: 10%;
    }

    .history-table td {
        font-size: 0.45rem;
    }
}
