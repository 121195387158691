.container {
    position: absolute;
    width: 100%;
    padding: 8px;
    top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 3px;
    margin-bottom: 20px;
}

.caption {
    font-size: 9px;
    text-align: right;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .flexContainer {
        display: block;
    }
}

.vipStatus {
    color: red;
    font-weight: bold;
    font-size: 20px;
}

.balanceContainer {
    display: flex;
    direction: row;
    margin-bottom: 8px;
}

.balanceWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.currentBalance {
    display: inline;
    margin-left: 8px;
    color: #44a340;
}

.currencyLabel {
    font-size: 12px;
    margin-left: 3px;
    color: #44a340;
}

.updateBalanceButton {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 10px;
}

.buttonOpenBalancesPage {
    width: 210px;
    height: 40px;
    border-radius: 20px !important;
    border: 1px solid !important;
    font-weight: 500 !important;
    text-transform: initial !important;
    margin-right: 8px !important;
    margin-bottom: 10px !important;
    border-color: #58a315 !important;
    color: #58a315 !important;
}

.balanceSummary {
    font-weight: 700;
    margin: 0 5px;
}

.transactionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

@media (max-width: 600px) {
    .transactionsContainer {
        display: block;
    }
}

.buttonOpenModalActiveTransactions {
    margin: 5px !important;
    padding: 10px !important;
    border-radius: 20px !important;
    color: red !important;
}

.buttonTransferSorocoins {
    min-width: 170px !important;
    height: 40px !important;
    border-radius: 20px !important;
    border: 1px solid !important;
    font-weight: 500 !important;
    text-transform: initial !important;
    margin-right: 8px !important;
    margin-bottom: 10px !important;
    border-color: #58a315 !important;
    color: #58a315 !important;
}

.buttonDepositMoney {
    min-width: 170px !important;
    border-radius: 20px !important;
    text-transform: initial !important;
    font-weight: 500 !important;
    margin-bottom: 10px !important;
}

.buttonRegisterInSOROBANK {
    border-radius: 20px !important;
    padding: '10px';
}
