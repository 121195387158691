.top_main_block_setting {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.MuiTabs-flexContainer {
  background-color: #fff;
  color: #000;
}
/* .MuiTabs-indicator {
  background-color: #ffffff;
} */
.top_setting.item {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: column;
}

.MuiAppBar-colorPrimary {
  background-color: #fff!important;
}

.MuiBox-root-9 {
  /*padding: 25px 0px!important;*/
}
.MuiBox-root-5 {
  /*padding: 25px 0px!important;*/
}
