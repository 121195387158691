.dashboard_stud {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: inherit;
}
.stud_item {
  width: 50%;
}
.teacher_exercise {
  background-color: #fff;
  padding: 6vh;
  margin: 2vh 0;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
}
