.counter {
  background-color: #59a314;
  color: #fff;
  padding: 5px 20px;
  font-weight: 100;
  border-radius: 50px;
  margin-left: 25px;
}
.ar_cards nav {
  margin: 10px 10px 0px 10px;
  box-shadow: 2px 2px 4px 3px #00000017;
  display: inline-block;
  vertical-align: text-top;
  width: 23%;
}

.MuiPaper-root.MuiAccordion-root.discounts_accordion::before {
  content: "";
  display: none;
}

@media screen and (max-width: 1400px) {
  .ar_cards nav {
    width: 30%;
  }
}

@media screen and (max-width: 1100px) {
  .ar_cards nav {
    width: 46%;
  }
}

@media screen and (max-width: 768px) {
  .ar_cards nav {
    width: 92%;
  }
}
