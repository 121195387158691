.plate-wrapper {
  /* width: 100%; */
  /* height: 10%; */
  padding: 1%;
  margin: 0;
  background-color: #00a10c;
  display: flex;
  flex-direction: row;
}
.cell {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  /*width: 20%;*/
  padding: 2% 2%;
  align-items: center;
  justify-content: space-around;
}

.cell p {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
  flex: 1 0 auto;
}

.cell-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  padding: 0.5% 2%;
  align-items: center;
  justify-content: space-around;
}

span.plate_name {
  color: #fff;
  font-weight: 700;
}

button.show_data {
  background-color: unset;
  padding: 1vh;
  font-weight: 700;
  border: 2px solid #fff;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

select.choose_plate_select {
  background-color: #fff;
  border: 0;
  padding: 0.5vh 1vh;
  font-size: 14px;
  cursor: pointer;
}
button.show_data:hover {
  color: #00af1d;
  background-color: #fff;
}
/*.mini-card {*/
/*    width: 50%;*/
/*    background: #FFFFFF;*/
/*    border-radius: 1px;*/
/*    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);*/
/*    font-size: 0.75em;*/
/*    color: rgba(0, 0, 0, 0.75);*/
/*    padding: 1% 1%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-content: center;*/
/*    justify-content: space-between;*/
/*}*/



@media screen and (max-width: 700px) {

.plate-wrapper {
    flex-direction: column;
}

.cell-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 0.5% 2%;
    align-items: center;
    justify-content: space-between;
}

.choose_plate_select {
    flex: 0 0 60%;
}
}
