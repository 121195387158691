.MuiPaper-root.olympiad_table.MuiPaper-elevation1.MuiPaper-rounded {
  margin-bottom: 15px;
}

.MuiTabs-root.olymp_swipe {
    margin-bottom: 15px;
    box-shadow: 0px 3px 5px 1px #0000001c;
}
.olympiad_loading {
  margin-bottom: 15px;
}
