.calendar {
    max-width: 90%;
    padding: 5%;
    border: 1px solid #00000;

}
h2.container-header.group {
    margin-top: 10px;
}

.space_for_message {
    padding: 3% 0;
}

.space_for_message > h4 {
    margin-bottom: 20px;
}

.fc-content-skeleton thead tr {
    height: 77px;
}

.fc-toolbar.fc-header-toolbar {
    padding: 25px;
}

.space_for_message > button {
    margin: 10px 0!important;
}

.fc-toolbar h2 {
    font-weight: 100;
}

.fc-button-primary {
    background-color: unset!important;
    border-color: unset!important;
    /*color: #000!important;*/
}

.panel-back.themeColorWHITE .fc-button-primary {
    color: #000!important;

}


span.fc-time {
    display: none;
}
