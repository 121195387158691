.MuiPaper-root.help_page_sort.MuiPaper-elevation1.MuiPaper-rounded {
    padding: 20px;
    margin-bottom: 20px;
}

.MuiPaper-root.help_page_sort.MuiPaper-elevation1.MuiPaper-rounded h4 {
    font-size: 17px;
}

iframe.helpPageIframe {
  border: 0;
  box-shadow: 0 2px 5px 0 #00000052;
  border-radius: 5px;
  height: 270px;
  width: 500px;
}

.videohelperContainer {
  margin-bottom: 20px;
}

.helperContainer {
  margin-bottom: 10px;
}

.helperContainer.item
  button.MuiButtonBase-root.MuiIconButton-root.addHelperButton {
  /* margin-bottom: 10px; */
}

.helperContainer .MuiPaper-root > div.helpItems_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helperContainer.item .MuiTypography-root.MuiTypography-body1 {
  width: 95%;
  margin: 0 auto;
}

.helperContainer .MuiTypography-root.MuiTypography-body1 {
  width: 100%;
}

.helperContainer.item .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: 0px 2px 5px 0 #0000006e;
}

@media screen and (max-width: 768px) {
  iframe.helpPageIframe {
    width: 100%;
  }
}
