.header {
  width: 100%;
  height: 60px;
  /*background: white;*/
  /*position: relative;*/
  /*top: 0;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /*box-shadow: 0 1px 2px rgb(0 0 0 / 9%);*/
  font-size: 0.8em;
  z-index: 10000;
}

.header-logo {
  flex: 0 0 14.4%;
  display: flex;
  align-items: center;
  /*background: white;*/
  justify-content: flex-start;
  margin-left: 20px;
  align-content: center;
}

.language_item {
  display: flex;
  align-items: center;
  margin-right: 15px!important;
}

.language_item .MuiFormControl-root {
  margin-left: 12px;
}

.header-logo img {
  width: 145px;
}

.menu {
  flex: 1;
  display: flex;
  /*background: white;*/
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.user-menu {
  justify-content: center;
  align-content: center;
  text-align: center;
}

.user-menu-open {
  justify-content: center;
  align-content: center;
  margin-right: 5%;
  z-index: 5;
}

.language-menu {
  justify-content: center;
  margin-right: 5%;
  color: #ff6600;
}

.modal-view {
  position: fixed;
  top: 10%;
}

.dropdown-menu {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
}
.modal_close_background {
  position: fixed;
  height: 100vh;
  width: 100%;
  right: 0;
  top: 0;
}
.ul-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 95px;
  background-color: white;
  font-size: 0.85em;
  font-weight: bold;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  border-radius: 5px;
  right: 30px;
}
.ul-menu li a {
  text-decoration: none;
}

a::after {
  text-decoration: none;
}

.ul-menu li {
  flex: 1 0 0;
  padding: 3px 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  /* opacity: 0.8; */
  transition: all 0.1s;
}
ul.ul-menu p {
  font-size: 14px;
  font-weight: 100;
}
ul.ul-menu svg {
  font-size: 24px;
}
.ul-menu li:last-child {
  border-bottom: none;
}
.ul-menu li:hover {
  background-color: #0000000d;
  opacity: 0.95 !important;
}
.button {
  font-weight: bold;
}

.usermenu-icon {
  margin-right: 5%;
  width: 32px;
  opacity: 0.85 !important;
}

.dropdown-menu.button svg {
  font-size: 20px;
}

.menu-default-view {
  display: block;
  font-weight: 100;
}

.menu-mobile-view {
  display: none;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .header {
    height: 45px;
    background: unset;
    box-shadow: unset;
  }
  .menu {
    background: unset;
  }
  .header-logo {
    flex: 0 0 50%;
    margin-left: 15%;
  }
  .header-logo {
    display: none;
  }

  .menu-default-view {
    display: none;
  }

  .menu-mobile-view {
    display: block;
  }

  .ul-menu {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    /* display: flex; */
    cursor: pointer;
    /* align-items: center;
      user-select: none; */
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .header-logo {
    flex: 0 0 30%;
    margin-left: 15%;
  }
}
