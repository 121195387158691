.sidebar-wrapper {
    width: 100px;
    transition: width 0.3s ease, padding 0.3s ease;
}

.hamburger-menu {
    height: 100%;
}

.sidebar-container {
    transition: width 0.3s ease, padding 0.3s ease;
    overflow: hidden;
    width: 100px;
    height: 100%;
    font-size: 12px;
    text-align: center;
    overflow: auto;
    padding-bottom: 100px;
}

.sidebar-container.collapsed {
    width: 70px; /* Ширина для свернутого меню */
}

.sidebar-wrapper.collapsed {
    transition: width 0.3s ease, padding 0.3s ease;
    width: 60px;
}

.sidebar-list li {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.sidebar-container.collapsed .sidebar-list li {
    opacity: 0.7;
}

.sidebar-container.collapsed .sidebar-list li.is-active {
    opacity: 1;
}

.sidebar-container.collapsed .sidebar-list li:hover {
    opacity: 1;
}

.sidebar-list {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    justify-content: flex-start;
    align-content: center;
}
.sidebar-list li {
    flex-direction: column;
    flex: 0 0 5.5%;
    list-style-type: none;
    display: flex;
    align-content: center;
    font-weight: lighter;
    cursor: pointer;
    outline: none;
    margin: 2%;
    padding: 4px 0;
    font-size: 11px;
    align-items: center;
    color: #606060;
    font-weight: 800;
}

ul.sidebar-list a.is-active {
    background-color: #b9b9b940;
    border-radius: 0px 5px 5px 0px;
}

ul.sidebar-list li.is-active {
    box-shadow: 0 0 4px 2px #0000001a;
    opacity: 1;
}

.sidebar-list .is-active li .border_hidden {
    width: 3px;
    background-color: #ff6600;
    height: 3.6vh;
    position: absolute;
    z-index: 9999999999;
    opacity: 1;
    right: -5px;
}

.menu-icon {
    margin: 0 15px;
    opacity: 1 !important;
}

.esoroban-icon {
    width: 3em;
}

a {
    outline: none;
}

ul.sidebar-list a {
    /* font-size: 1.1em; */
    transition: all 0.1s;
    font-weight: 700;
}

.header_logo {
    display: none;
}

/* hamburger menu */

#menu__toggle {
    opacity: 0;
}

/* .hamburger-menu {
     display: none;
 } */

#menu__toggle {
    display: none;
}

.sidebar-list a {
    outline: none;
    outline-offset: unset;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    ul.sidebar-list a.is-active {
        border-radius: 0px;
    }
    .sidebar-wrapper {
        min-width: unset;
        width: unset;
    }
    .black_back {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #00000047;
        z-index: -1;
        opacity: 0;
        transition: all 0.2s;
    }
    .header_logo {
        display: block;
        text-align: left;
    }
    .header_logo img {
        width: 60%;
        padding: 0 7%;
    }

    a.is-active {
        background-color: #f47137;
    }
    /* .base-container also moving */

    .hamburger-menu {
        display: block;
    }

    .menu__btn {
        display: flex;
        align-items: center;
        position: absolute;
        top: 12px;
        left: 3%;
        width: 26px;
        height: 26px;
        cursor: pointer;
        z-index: 6;
    }

    ul.sidebar-list svg {
        font-size: 20px;
    }

    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #616161;
    }
    .menu__btn > span::before {
        content: '';
        top: -8px;
    }
    .menu__btn > span::after {
        content: '';
        top: 8px;
    }

    .menu__box {
        position: fixed;
        visibility: hidden;
        top: 0;
        left: -100%;
        width: 300px;
        height: 100%;
        margin: 0;
        padding: 20px 0;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 5px 0 14px 0 rgba(0, 0, 0, 0.2);
        z-index: 6;
    }

    .menu__item {
        padding: 12px 24px;
        color: #333;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
    }
    .menu__item:hover {
        background-color: #cfd8dc;
    }

    #menu__toggle:checked ~ .menu__btn > span {
        transform: rotate(45deg);
    }
    #menu__toggle:checked ~ .menu__btn > span::before {
        top: 0;
        transform: rotate(0);
    }
    #menu__toggle:checked ~ .menu__btn > span::after {
        top: 0;
        transform: rotate(90deg);
    }
    #menu__toggle:checked ~ .menu__box {
        visibility: visible;
        left: 0;
    }
    #menu__toggle:checked ~ .black_back {
        opacity: 1;
        z-index: 5;
    }

    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
        transition-duration: 0.25s;
    }
    .menu__box {
        transition-duration: 0.25s;
    }
    .menu__item {
        transition-duration: 0.25s;
    }
}
