ul.payments_methods {
  list-style: none;
  margin: 0;
  padding-left: 25px;
}

span.cityPrice.ok {
  background-color: #59a314;
  color: #fff;
}

span.cityPrice.not_shown {
  background-color: #f36630;
  color: #fff;
}

span.cityPrice {
  padding: 5px 10px;
  border-radius: 7px;
}

li.payments-payments__item {
  display: inline-block;
}

.paymentsAccordion .MuiAccordionSummary-content {
  justify-content: space-between;
}

.paymentsAccordion .MuiAccordionSummary-content .MuiTypography-root.MuiTypography-body1 {
  width: unset;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.payments-payments__button {
  margin-right: 10px;
  outline: 0;
  outline-offset: 0;
  border: 0;
  box-shadow: 0 1px 4px 0 #00000045;
  transition: all 0.2s;
  cursor: pointer;
}

.payments-payments__button:hover {
  background-color: #59a31552;
}

.payments-payments__button img {
  width: 50px;
}

.debt_container p,
h2 {
  margin-left: 25px;
}

.debt_container h2 span:hover {
  box-shadow: 0 1px 4px 0 #00000045;
}

.debt_container h2 span.debt {
  background-color: #f36630;
}

.debt_container h2 span.ok {
  background-color: #59a315;
}

.debt_container h2 span {
  background-color: #00000054;
  padding: 7px 20px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.fop_data {
  display: inline-block;
  width: 30%;
  margin: 12px;
  position: relative;
  height: 400px;
  overflow: auto !important;
}

.fop_data.inactive {
  pointer-events: none;
}

.items_fop_bill_background {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000007a;
  z-index: 99999999;
  pointer-events: none;
}

.fop_data .avatar {
  background-color: #59a313;
}

.fop_data .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.fop_data .delete_icon svg {
  color: red;
}

.fop_data .save_icon svg {
  color: #59a313;
}

.payents_top_bar {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #00a10c;
  display: flex;
  flex-direction: row;
}

.payents_top_bar span {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  padding: 2% 2%;
  align-items: center;
  justify-content: space-around;
}

span.text_yellow {
  color: #fbae01;
}

.phone_hint {
  font-size: 12px;
  opacity: 0.7;
}

.phone_hint.error {
  color: red;
}

span.text_red {
  color: red;
}
.teacher_exercise {
  background-color: #fff;
  padding: 6vh;
  margin: 4vh 0;
}

.teacher_exercise_item span {
  vertical-align: top;
}

.teacher_exercise_item svg {
  vertical-align: text-top;
}

@media screen and (max-width: 768px) {
  .fop_data {
    width: 93%;
    height: unset;
  }
}
