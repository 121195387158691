.calendar {
  max-width: 90%;
  padding: 5%;
  border: 1px solid #00000;
}
.top_main_block_setting {
  display: flex;
  justify-content: flex-start !important;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 15px;
}
.paginate_days {
  display: flex;
  align-items: center;
}
.paginate_days button {
  height: 45px;
}
.MuiNativeSelect-select:focus {
  background-color: unset !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: column;
}

.top_setting {
  text-align: center;
  background-color: #e8e8e8;
  padding: 10px 23px;
  border-radius: 5px;
  margin: 0 15px;
  width: auto;
  color: #676767;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.top_main_block_setting h3 {
  margin: auto 22px;
  color: #7b7b7b;
}

.top_main_block_setting select {
  color: #404040 !important;
}

.top_setting p {
  margin: 10px 10px;
}
.MuiTabs-flexContainer {
  background-color: #ff6600;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #58a315 !important;
  margin-right: 8px;
}

button.MuiButtonBase-root.MuiButton-root.filterButtons.clear {
  color: #fff;
  background-color: #ff3838;
  border: none;
}

button.MuiButtonBase-root.MuiButton-root.filterButtons {
  border-radius: 50px;
  text-transform: initial;
  border: none;
}

@media screen and (max-width: 768px) {
  .top_main_block_setting {
    display: block !important;
    margin-bottom: 20px;
    text-align: center;
  }
  .top_main_block_setting div {
    margin: 5px;
  }
  .paginate_days {
    justify-content: center;
  }
}
