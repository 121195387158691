.back-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #e5e5e5;
  flex-direction: column;
  background-position: bottom;
  background-repeat: no-repeat;
}

.main_container {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 360px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
  padding: 35px 20px;
}

.check {
  color: #58a315 !important;
}

.main-container.reset {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: unset;
  flex-wrap: unset;
  position: unset;
}

.main-container.reset.select {
  flex-direction: inherit;
  width: 30%;
}

.arrowBack {
  cursor: pointer;
}

img.logo-pic {
  width: 150px;
}

.main-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-content: center;
}

.back-container .orangeBtn:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #59a314;
  color: #59a314 !important;
}

.main_logo {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login_input {
  width: 100%;
}

.login_btn {
  width: 100%;
}

.logo-pic {
  width: 23%;
  justify-content: center;
  align-content: center;
  margin-bottom: 2%;
}

.MuiInput-underline:before {
  border: 0 !important;
}

.back-container h2 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.7em;
  color: #ff6600;
  justify-content: center;
  align-content: center;
  margin: 0 35%;
  text-align: center;
}

.back-container h3 {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4em;
  color: #ff6600;
  justify-content: center;
  align-content: center;
  /* margin: 0 35%; */
  text-align: center;
}

.back-container span.reset-password {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.7em;
  color: #ff6600;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  margin-top: 3vh;
  margin-bottom: 3vh;
  cursor: pointer;
}

.back-container .inputs {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 2vh;
  margin-top: 3vh;
}

.back-container .orangeBtn {
  background-color: #59a314;
  color: #fff;
  border: 1px solid #59a314;
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .main_container {
    max-width: 300px;
  }

  .logo-pic {
    width: 45%;
    justify-content: center;
    align-content: center;
    margin-bottom: 2%;
  }

  .back-container h2 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9em;
    color: #ff6600;
    justify-content: center;
    align-content: center;
    margin: 0 35%;
    text-align: center;
  }
  .main-container.reset.select {
    width: 80%;
  }

  .main-container {
    min-width: unset;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0;
    margin: unset;
  }
}
